import { Fragment } from 'react';

import { CouplesStorefrontDetailsOfficiant } from '~/types/storefrontDetails';

import { AvailabilityCalendar } from '../components/AvailabilityCalendar';
import FaqsSection from '../components/FaqsSection';
import JumpMenuSection, { JumpMenuSectionIds } from '../components/JumpMenuSection';
import OtherVendorsSection from '../components/OtherVendorsSection';
import PackagesSection from '../components/PackagesSection';
import RealWeddingsSection from '../components/RealWeddingsSection';
import ReviewsSection from '../components/ReviewsSection';
import VendorStorefrontSection from '../components/VendorStorefrontSection';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { filterForParentKey, serviceBlock } from '../helpers/vendor';

const OfficiantDetails = () => {
  const { storefrontDetails, otherServices } =
    useStorefrontDetails<CouplesStorefrontDetailsOfficiant>();
  const { options, name, preferredVendors, packages } = storefrontDetails;
  const ceremonyTypesOffered = filterForParentKey('officiants-services-ceremony-types', options);
  const religiousAffiliationsOffered = filterForParentKey(
    'officiants-services-religious-affiliations',
    options
  );
  const hasServices = [ceremonyTypesOffered, religiousAffiliationsOffered].some(
    (services) => services.length > 0
  );

  const hasServiceOrPackage = hasServices || !!packages?.length;

  return (
    <Fragment>
      {hasServiceOrPackage && (
        <JumpMenuSection sectionName={JumpMenuSectionIds.ServicesPricing}>
          {hasServices && (
            <VendorStorefrontSection hed="Services" className="container">
              {serviceBlock(
                ceremonyTypesOffered.length > 0,
                'Ceremony types',
                ceremonyTypesOffered,
                true
              )}
              {serviceBlock(
                religiousAffiliationsOffered.length > 0,
                'Religious affiliations',
                religiousAffiliationsOffered,
                true
              )}
            </VendorStorefrontSection>
          )}
          <PackagesSection />
        </JumpMenuSection>
      )}
      <ReviewsSection />
      <OtherVendorsSection title={`Preferred vendors of ${name}`} vendors={preferredVendors} />
      <OtherVendorsSection title={`More wedding services from ${name}`} vendors={otherServices} />
      <RealWeddingsSection />
      <FaqsSection />
      <AvailabilityCalendar />
    </Fragment>
  );
};

export default OfficiantDetails;
