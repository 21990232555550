import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  EXTRAS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  OFFICIANTS_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import { AnyVendorDetails, VendorCardView } from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';

/**
 * Determines if a storefront detail page is being viewed in preview mode by the vendor
 */
export const isPreview = (): boolean => {
  return typeof window !== 'undefined' && window.location.pathname.endsWith('/preview');
};

/**
 * Determines if a storefront detail page is being viewed in split view preview mode by the vendor
 */
export const isSplitView = (): boolean => {
  return typeof window !== 'undefined' && window.location.pathname.includes('/split-view/');
};

export const getBioPhotoImageId = (
  storefront: CouplesStorefrontDetails,
  useCoverGalleryAsFallback = true
): string => {
  const coverGalleryImageId = storefront?.coverGallery?.[0]?.uuid as string;
  let bioPhotoImageId: string | null = null;

  switch (storefront.taxonomyKey) {
    case PHOTOGRAPHERS_TAXONOMY_KEY: {
      ({ bioPhotoImageId } = storefront.photographerDetails);
      break;
    }
    case VIDEOGRAPHERS_TAXONOMY_KEY: {
      ({ bioPhotoImageId } = storefront.videographerDetails);
      break;
    }
    case PLANNERS_TAXONOMY_KEY: {
      ({ bioPhotoImageId } = storefront.weddingPlannerDetails);
      break;
    }
    case FLORISTS_TAXONOMY_KEY: {
      ({ bioPhotoImageId } = storefront.floristDetails);
      break;
    }
    case BANDS_DJS_TAXONOMY_KEY: {
      ({ bioPhotoImageId } = storefront.musicianDetails);
      break;
    }
    case CATERING_TAXONOMY_KEY:
    case BAR_SERVICES_TAXONOMY_KEY: {
      ({ bioPhotoImageId } = storefront.catererDetails);
      break;
    }
    case CAKES_DESSERTS_TAXONOMY_KEY: {
      ({ bioPhotoImageId } = storefront.bakerDetails);
      break;
    }
    case HAIR_MAKEUP_TAXONOMY_KEY: {
      ({ bioPhotoImageId } = storefront.beauticianDetails);
      break;
    }
    case OFFICIANTS_TAXONOMY_KEY: {
      ({ bioPhotoImageId } = storefront.officiantDetails);
      break;
    }
    case EXTRAS_TAXONOMY_KEY: {
      ({ bioPhotoImageId } = storefront.extrasDetails);
      break;
    }
    default: {
      break;
    }
  }
  return bioPhotoImageId || (useCoverGalleryAsFallback ? coverGalleryImageId : '');
};

/**
 * Determines whether the storefront is claimed by a business
 */
export const isClaimed = (
  storefront?: Pick<CouplesStorefrontDetails, 'claimedAt'> | null
): boolean => Boolean(storefront?.claimedAt);

/**
 * Gets category-specific details for a storefront
 */
export const getVendorDetails = (
  storefrontDetails: CouplesStorefrontDetails
): AnyVendorDetails | null => {
  switch (storefrontDetails.taxonomyKey) {
    case BANDS_DJS_TAXONOMY_KEY:
      return storefrontDetails.musicianDetails;
    case CAKES_DESSERTS_TAXONOMY_KEY:
      return storefrontDetails.bakerDetails;
    case CATERING_TAXONOMY_KEY:
    case BAR_SERVICES_TAXONOMY_KEY:
      return storefrontDetails.catererDetails;
    case EXTRAS_TAXONOMY_KEY:
      return storefrontDetails.extrasDetails;
    case FLORISTS_TAXONOMY_KEY:
      return storefrontDetails.floristDetails;
    case HAIR_MAKEUP_TAXONOMY_KEY:
      return storefrontDetails.beauticianDetails;
    case OFFICIANTS_TAXONOMY_KEY:
      return storefrontDetails.officiantDetails;
    case PHOTOGRAPHERS_TAXONOMY_KEY:
      return storefrontDetails.photographerDetails;
    case PLANNERS_TAXONOMY_KEY:
      return storefrontDetails.weddingPlannerDetails;
    case VENUES_TAXONOMY_KEY:
      return storefrontDetails.venueDetails;
    case VIDEOGRAPHERS_TAXONOMY_KEY:
      return storefrontDetails.videographerDetails;
    default:
      return null;
  }
};

/**
 * Gets the storefronts for a company excluding the specified storefront
 */
export const getOtherServices = (storefrontUuid: string, companyStorefronts: VendorCardView[]) => {
  return companyStorefronts.filter(
    (companyStorefront) => companyStorefront.storefrontUuid !== storefrontUuid
  );
};
