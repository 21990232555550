import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import { Link as ReactLink } from 'react-router-dom';

interface LinkBlockProps {
  children: React.ReactNode;
  backTo: string;
  disableSave?: boolean;
  backOnClick?: () => void;
  awaitBack?: () => void;
}

const LinkBlock = ({
  children,
  backOnClick,
  awaitBack,
  disableSave,
  backTo,
}: LinkBlockProps): JSX.Element => {
  const Block = () => {
    if (backOnClick) {
      return (
        <LinkV2
          arrow
          arrowPosition="prefix"
          role="button"
          onClick={backOnClick}
          className="nav__back-link"
        >
          {children}
        </LinkV2>
      );
    }
    if (awaitBack && !disableSave) {
      return (
        <span onClick={awaitBack} className="nav__back-link link link-arrow prefix">
          {children}
        </span>
      );
    }
    return (
      <ReactLink to={backTo} className="nav__back-link link link-arrow prefix">
        {children}
      </ReactLink>
    );
  };
  return <Block />;
};

export default LinkBlock;
