import { Fragment } from 'react';

import { MappedOptionFacetView } from '~/types/responseTypes';
import { CouplesStorefrontDetailsExtras } from '~/types/storefrontDetails';
import {
  isExtrasDayOfWeddingServices,
  isExtrasDecorRentalsServices,
  isExtrasPrePostWeddingServices,
} from '~/util/vendorUtils';

import { AvailabilityCalendar } from '../components/AvailabilityCalendar';
import FaqsSection from '../components/FaqsSection';
import JumpMenuSection, { JumpMenuSectionIds } from '../components/JumpMenuSection';
import OtherVendorsSection from '../components/OtherVendorsSection';
import PackagesSection from '../components/PackagesSection';
import RealWeddingsSection from '../components/RealWeddingsSection';
import ReviewsSection from '../components/ReviewsSection';
import VendorStorefrontSection from '../components/VendorStorefrontSection';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { filterForParentKey, serviceBlock } from '../helpers/vendor';

const ExtrasDetails = () => {
  const { storefrontDetails, otherServices } =
    useStorefrontDetails<CouplesStorefrontDetailsExtras>();
  const { options, name, preferredVendors, packages } = storefrontDetails;
  const servicesOffered = filterForParentKey('extras-services', options);
  // Extras service facets are mapped to the following vendor type facets on the back end. However,
  // there's no parent/child relationship between the service and vendor type facets, so we need to
  // create the display groups here.
  const decorRentalsOffered: MappedOptionFacetView[] = [];
  const prePostWeddingServicesOffered: MappedOptionFacetView[] = [];
  const dayOfWeddingServicesOffered: MappedOptionFacetView[] = [];
  servicesOffered.forEach((facet) => {
    if (isExtrasDecorRentalsServices(facet.key)) {
      decorRentalsOffered.push(facet);
    }
    if (isExtrasPrePostWeddingServices(facet.key)) {
      prePostWeddingServicesOffered.push(facet);
    }
    if (isExtrasDayOfWeddingServices(facet.key)) {
      dayOfWeddingServicesOffered.push(facet);
    }
  });
  const hasServices = [
    decorRentalsOffered,
    prePostWeddingServicesOffered,
    dayOfWeddingServicesOffered,
  ].some((services) => services.length > 0);

  const hasServiceOrPackage = hasServices || !!packages?.length;

  return (
    <Fragment>
      {hasServiceOrPackage && (
        <JumpMenuSection sectionName={JumpMenuSectionIds.ServicesPricing}>
          {hasServices && (
            <VendorStorefrontSection hed="Services" className="container">
              {serviceBlock(
                decorRentalsOffered.length > 0,
                'Decor & rentals',
                decorRentalsOffered,
                true
              )}
              {serviceBlock(
                prePostWeddingServicesOffered.length > 0,
                'Pre & post wedding services',
                prePostWeddingServicesOffered,
                true
              )}
              {serviceBlock(
                dayOfWeddingServicesOffered.length > 0,
                'Day of services & entertainment',
                dayOfWeddingServicesOffered,
                true
              )}
            </VendorStorefrontSection>
          )}
          <PackagesSection />
        </JumpMenuSection>
      )}
      <ReviewsSection />
      <OtherVendorsSection title={`Preferred vendors of ${name}`} vendors={preferredVendors} />
      <OtherVendorsSection title={`More wedding services from ${name}`} vendors={otherServices} />
      <RealWeddingsSection />
      <FaqsSection />
      <AvailabilityCalendar />
    </Fragment>
  );
};

export default ExtrasDetails;
