import { useState, useEffect } from 'react';

import { VENUES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { OverflowEllipsis } from '@zola/zola-ui/src/components/OverflowEllipsis/OverflowEllipsis';
import usePrevious from '@zola/zola-ui/src/hooks/usePrevious';
import { TitleMedium3 } from '@zola/zola-ui/src/typography/Headings';

import { forceCheck } from 'react-lazyload';

import CachedImage from '~/components/common/images/CachedImage';
import CircleImage from '~/components/common/images/CircleImage';
import Facets from '~/meta/facets';
import { CatererStoryView, MappedOptionFacetView } from '~/types/responseTypes';
import { getImageUrl } from '~/util/imageUtils';
import { centsToDollars } from '~/util/priceConversion';
import getCanonicalUrl from '~/util/storefrontUrl';
import { formatAsCurrency } from '~/util/textUtils';

import ServiceBlock from '../../components/ServiceBlock';

import './ShowcaseStorySection.less';

export interface ShowcaseStorySectionProps {
  showcase: CatererStoryView;
}

const ShowcaseStorySection = ({ showcase }: ShowcaseStorySectionProps) => {
  // Make sure that when the tab changes (show a different showcase) the image are shown
  const [uuid, setUuid] = useState<string>(showcase.uuid);
  useEffect(() => {
    setUuid(showcase.uuid);
  }, [showcase]);

  const previousUuid = usePrevious(uuid);
  useEffect(() => {
    if (previousUuid !== uuid && previousUuid) {
      forceCheck();
    }
  }, [uuid, previousUuid]);

  const coverPhotos = showcase.coverPhotoViews || [];
  const options = (showcase.entityFacetViews || []) as MappedOptionFacetView[];
  const figures = [
    {
      label: 'Guest Count',
      value: showcase.guestCount,
    },
    {
      label: 'Total Catering Cost',
      value: formatAsCurrency(centsToDollars(showcase.cost)),
    },
  ];
  const services = options.filter((option) => option.parentKey !== Facets.MENU_FORMATS_OFFERED.key);
  const highlights = [
    ...options
      .filter((option) => option.parentKey === Facets.MENU_FORMATS_OFFERED.key)
      .map((item) => item.name),
  ];
  if (showcase.coursesOffered) {
    highlights.push(showcase.coursesOffered);
  }

  return (
    <div className="showcase-stories-section__container">
      <div className="left">
        {showcase.vendorCard && (
          <div className="venue-information">
            {showcase.vendorCard.storefrontCoverId && (
              <CircleImage
                className="venue-avatar"
                src={getImageUrl(showcase.vendorCard.storefrontCoverId) || ''}
              />
            )}
            {showcase.vendorCard.storefrontUuid && showcase.vendorCard.storefrontSlug ? (
              <LinkV2
                arrow
                arrowPosition="prefix"
                href={getCanonicalUrl(VENUES_TAXONOMY_KEY, showcase.vendorCard.storefrontSlug)}
              >
                {showcase.vendorCard.vendorName}
              </LinkV2>
            ) : (
              <h5 className="venue-name">{showcase.vendorCard.vendorName}</h5>
            )}
          </div>
        )}
        <div className="dek">{showcase.inspiration}</div>
        {figures.length > 0 && (
          <div className="figures content-section">
            {figures.map((num, idx) => (
              <div className="num-block mt-tertiary mb-tertiary" key={`figures${idx}`}>
                <TitleMedium3 className="title" presentation="h6">
                  {num.label}
                </TitleMedium3>
                <span className="value mt-quaternary">{num.value}</span>
              </div>
            ))}
          </div>
        )}
        {highlights.length > 0 && (
          <div className="content-section">
            <TitleMedium3 className="title" presentation="h6">
              Menu Highlights
            </TitleMedium3>
            <ul>
              {highlights.map((item, idx) => (
                <li className="mt-quaternary" key={`highlight${idx}`}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
        {services.length > 0 && (
          <div className="content-section">
            <ServiceBlock label="Services Included" options={services} />
          </div>
        )}
      </div>
      {coverPhotos.length > 0 && (
        <div className="right">
          <div className="images">
            <figure className="top">
              {coverPhotos[0].imageId && (
                <CachedImage uuid={coverPhotos[0].imageId} width={565} height={352} fit="crop" />
              )}
              {coverPhotos[0].caption && (
                <figcaption title={coverPhotos[0].caption}>
                  <OverflowEllipsis lines={2}>{coverPhotos[0].caption}</OverflowEllipsis>
                </figcaption>
              )}
            </figure>
            <figure className="left">
              {coverPhotos[1].imageId && (
                <CachedImage uuid={coverPhotos[1].imageId} width={289} height={361} fit="crop" />
              )}
              {coverPhotos[1].caption && (
                <figcaption title={coverPhotos[1].caption}>
                  <OverflowEllipsis lines={2}>{coverPhotos[1].caption}</OverflowEllipsis>
                </figcaption>
              )}
            </figure>
            <figure className="rightTop">
              {coverPhotos[2].imageId && (
                <CachedImage uuid={coverPhotos[2].imageId} width={265} height={175} fit="crop" />
              )}
              {coverPhotos[2].caption && (
                <figcaption title={coverPhotos[2].caption}>
                  <OverflowEllipsis lines={2}>{coverPhotos[2].caption}</OverflowEllipsis>
                </figcaption>
              )}
            </figure>
            <figure className="rightBottom">
              {coverPhotos[3].imageId && (
                <CachedImage uuid={coverPhotos[3].imageId} width={265} height={175} fit="crop" />
              )}
              {coverPhotos[3].caption && (
                <figcaption className="caption" title={coverPhotos[3].caption}>
                  <OverflowEllipsis lines={2}>{coverPhotos[3].caption}</OverflowEllipsis>
                </figcaption>
              )}
            </figure>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowcaseStorySection;
