import { Fragment, useEffect, useState } from 'react';

import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  EXTRAS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  OFFICIANTS_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomySwitchFunc } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';

import cx from 'classnames';

import { useUserContext } from '~/contexts/UserContext';
import { useOnboardedViaFavoritedStorefront } from '~/hooks/useOnboardedViaFavoritedStorefront';
import { addRecentlyViewedStorefront } from '~/libs/client/api/recentlyViewed';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import featureFlags from '~/util/featureFlags';
import { isPreview, isSplitView } from '~/util/storefrontUtils';
import { TrackingProductLocation } from '~/util/trackingHelper';
import { isGuest } from '~/util/userContextUtils';

import BakerDetails from './bakers/BakerDetails';
import BeauticianDetails from './beauticians/BeauticianDetails';
import CatererDetails from './caterers/CatererDetails';
import FirstMoveSection from './components/FirstMoveSection';
import InquiryModule from './components/InquiryModule';
import JumpMenuSection, { JumpMenuSectionIds } from './components/JumpMenuSection';
import OverviewSection from './components/OverviewSection';
import { StorefrontHeader } from './components/StorefrontHeader';
import UnclaimedClaimModule from './components/UnclaimedClaimModule';
import UnclaimedModule from './components/UnclaimedModule';
import UnclaimedSimilarSection from './components/UnclaimedSimilarSection';
import VendorIntroSection from './components/VendorIntroSection';
import VideoSection from './components/VideoSection';
import { StorefrontDetailsLightboxProvider } from './contexts/LightboxProvider';
import { useStorefrontDetails } from './contexts/StorefrontDetailsContext';
import ExtrasDetails from './extras/ExtrasDetails';
import FloristDetails from './florists/FloristDetails';
import MusicianDetails from './musicians/MusicianDetails';
import OfficiantDetails from './officiants/OfficiantDetails';
import PhotographerDetails from './photographers/PhotographerDetails';
import PlannerDetails from './planners/PlannerDetails';
import VenueIntroSection from './venues/components/VenueIntroSection';
import WeddingScannerSection from './venues/components/WeddingScannerSection';
import VenueDetails from './venues/VenueDetails';
import VideographerDetails from './videographers/VideographerDetails';

import styles from './storefrontDetailsContent.module.less';

import './storefront.less';

const getStorefrontDetailsComponent = getVendorTaxonomySwitchFunc<SearchableVendorTaxonomyKey>({
  [BANDS_DJS_TAXONOMY_KEY]: <MusicianDetails />,
  [CAKES_DESSERTS_TAXONOMY_KEY]: <BakerDetails />,
  [CATERING_TAXONOMY_KEY]: <CatererDetails />,
  [BAR_SERVICES_TAXONOMY_KEY]: <CatererDetails />,
  [EXTRAS_TAXONOMY_KEY]: <ExtrasDetails />,
  [FLORISTS_TAXONOMY_KEY]: <FloristDetails />,
  [HAIR_MAKEUP_TAXONOMY_KEY]: <BeauticianDetails />,
  [OFFICIANTS_TAXONOMY_KEY]: <OfficiantDetails />,
  [PHOTOGRAPHERS_TAXONOMY_KEY]: <PhotographerDetails />,
  [PLANNERS_TAXONOMY_KEY]: <PlannerDetails />,
  [VENUES_TAXONOMY_KEY]: <VenueDetails />,
  [VIDEOGRAPHERS_TAXONOMY_KEY]: <VideographerDetails />,
});

const StorefrontContent = ({ hideCLPLPBreadcrumbs }: { hideCLPLPBreadcrumbs?: boolean }) => {
  const userContext = useUserContext();
  const { isDesktop } = useResponsiveDesign();
  const { storefrontDetails, firstMove, isUnclaimed } =
    useStorefrontDetails<CouplesStorefrontDetails>();
  const [showInquiryModule, setShowInquiryModule] = useState(true);

  // Remove this hook and the showInquiryModule flag along with the feature flag
  useEffect(() => {
    if (
      storefrontDetails.taxonomyKey === OFFICIANTS_TAXONOMY_KEY ||
      storefrontDetails.taxonomyKey === EXTRAS_TAXONOMY_KEY
    ) {
      setShowInquiryModule(featureFlags.get('enableOfficiantsAndExtras'));
    }
  }, [storefrontDetails]);

  useOnboardedViaFavoritedStorefront({
    showCustomInitialToasts: false,
    location: TrackingProductLocation.STOREFRONT_DETAIL_PAGE,
  });

  useEffect(() => {
    if (storefrontDetails && !isGuest(userContext)) {
      addRecentlyViewedStorefront({
        storefrontUuid: storefrontDetails.uuid,
        taxonomyNodeId: storefrontDetails.taxonomyNodeId,
      }).catch(() => null);
    }
  }, [storefrontDetails, userContext]);

  const IntroSection =
    storefrontDetails.taxonomyKey === VENUES_TAXONOMY_KEY ? VenueIntroSection : VendorIntroSection;

  return (
    <StorefrontDetailsLightboxProvider>
      <JumpMenuSection
        className={cx(styles.sectionsV2, styles.redesignHeaderContainer)}
        sectionName={JumpMenuSectionIds.Photos}
      >
        <StorefrontHeader hideCLPLPBreadcrumbs={hideCLPLPBreadcrumbs} />
      </JumpMenuSection>
      <div className={styles.sectionsV2}>
        <div className={cx(styles.content, { [styles.splitView]: isSplitView() })}>
          <JumpMenuSection sectionName={JumpMenuSectionIds.About}>
            {firstMove && <FirstMoveSection />}
            <OverviewSection />
            <VideoSection />
            {!isDesktop && isUnclaimed && <UnclaimedClaimModule />}
            {isUnclaimed && <UnclaimedSimilarSection />}
            <WeddingScannerSection />
          </JumpMenuSection>
          <IntroSection />
          {getStorefrontDetailsComponent(storefrontDetails.taxonomyKey)}
          {!isPreview() && !isUnclaimed && showInquiryModule && (
            <>
              <hr />
              <InquiryModule
                className={styles.contentInquiryModule}
                isFooter
                hideSocialProof
                hideVendorPromo
              />
            </>
          )}
        </div>
        <div
          className={cx(styles.module, {
            [styles.splitView]: isSplitView(),
          })}
        >
          <div className={styles.stickySection}>
            {!isPreview() && !isUnclaimed && showInquiryModule && <InquiryModule />}
            {isDesktop && isUnclaimed && (
              <Fragment>
                <UnclaimedModule />
                <UnclaimedClaimModule />
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </StorefrontDetailsLightboxProvider>
  );
};

export default StorefrontContent;
