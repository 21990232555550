import { Fragment } from 'react';

import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  EXTRAS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  OFFICIANTS_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomySwitchFunc } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';

import { AnyVendorDetails } from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { getVendorDetails } from '~/util/storefrontUtils';

import BeauticianOverview from '../beauticians/BeauticianOverview';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import useBreadcrumbLocation from '../hooks/useBreadcrumbLocation';
import { MusicianOverview } from '../musicians/MusicianOverview';
import { ListingMessage } from './ListingMessage';
import OverviewLocation from './OverviewLocation';
import Pricing, { hasPricing } from './Pricing';

import styles from './overviewSection.module.less';

const getOverviewComponent = getVendorTaxonomySwitchFunc<SearchableVendorTaxonomyKey>({
  [BANDS_DJS_TAXONOMY_KEY]: <MusicianOverview />,
  [CAKES_DESSERTS_TAXONOMY_KEY]: null,
  [CATERING_TAXONOMY_KEY]: null,
  [EXTRAS_TAXONOMY_KEY]: null,
  [BAR_SERVICES_TAXONOMY_KEY]: null,
  [FLORISTS_TAXONOMY_KEY]: null,
  [HAIR_MAKEUP_TAXONOMY_KEY]: <BeauticianOverview />,
  [OFFICIANTS_TAXONOMY_KEY]: null,
  [PHOTOGRAPHERS_TAXONOMY_KEY]: null,
  [PLANNERS_TAXONOMY_KEY]: null,
  [VENUES_TAXONOMY_KEY]: null,
  [VIDEOGRAPHERS_TAXONOMY_KEY]: null,
});

const OverviewSection = (): JSX.Element => {
  // TODO: add an anchor tag to the reviews section, re: https://www.npmjs.com/package/react-scrollable-anchor
  // or using something similar, so that people can link to it
  const { storefrontDetails, inquiryServices } = useStorefrontDetails<CouplesStorefrontDetails>();
  const vendorDetails = getVendorDetails(storefrontDetails) as AnyVendorDetails;
  const { name, markets } = storefrontDetails;
  const {
    selectedMarket,
    selectedMarketStateCode,
    nycDisplayHeaderText,
    isVendorLocationSameAsSearch,
    isRecordedLocationPresent,
    displayServiceMarket,
    displayTravelMarket,
    recordedLocation,
  } = useBreadcrumbLocation(storefrontDetails);

  const venueServiceLevel = inquiryServices.filter(
    (service) => service.parentKey === 'venue-service-level'
  );

  return (
    <div className={styles.overviewSection}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className={styles.hedContainer}>
              <h1 className={styles.hed} data-testid="listing-page-vendor-name">
                {name}
              </h1>
            </div>
            <OverviewLocation
              selectedMarket={selectedMarket}
              isVendorLocationSameAsSearch={isVendorLocationSameAsSearch}
              selectedMarketStateCode={selectedMarketStateCode as string | null}
              displayTravelMarket={displayTravelMarket}
              displayServiceMarket={displayServiceMarket}
              nycDisplayHeaderText={nycDisplayHeaderText}
              recordedLocation={recordedLocation}
              markets={markets}
              isRecordedLocationPresent={isRecordedLocationPresent}
            />
            {hasPricing({ data: storefrontDetails, details: vendorDetails }) ? (
              <>
                <hr className={styles.hr} />
                <Pricing venueServiceLevel={venueServiceLevel[0]} />
              </>
            ) : (
              <Fragment />
            )}
            {storefrontDetails?.listingMessage && (
              <ListingMessage message={storefrontDetails.listingMessage} />
            )}
            {getOverviewComponent(storefrontDetails.taxonomyKey)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewSection;
