import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  EXTRAS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  OFFICIANTS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  SEARCHABLE_VENDOR_TAXONOMY_KEYS,
  SearchableVendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import {
  getVendorWordPill,
  getVendorWordPlural,
} from '@zola-helpers/client/dist/es/marketplace/vendorWords';
import { SearchLocationView } from '@zola/svc-marketplace-ts-types';

import { getStateName } from '~/components/common/form/addresses';
import createCanonicalUrl from '~/pages/couples/explore/util/canonicalUrl';
import { OptionFacetType } from '~/types/types';

import { VENDOR_SEARCH_LANDING_PAGE_URL, vendorCategoryLandingUrl } from './vendorSearchUrl';

export const getLandingPageBreadcrumb = () => {
  return {
    label: 'Wedding Vendors',
    to: VENDOR_SEARCH_LANDING_PAGE_URL,
  };
};

const getSearchableVendorTaxonomyKey = (taxonomyKey: string) => {
  let vendorTaxonomyKey = getVendorTaxonomyKey(taxonomyKey) as
    | SearchableVendorTaxonomyKey
    | undefined;

  if (!vendorTaxonomyKey || !SEARCHABLE_VENDOR_TAXONOMY_KEYS.includes(vendorTaxonomyKey)) {
    vendorTaxonomyKey = VENUES_TAXONOMY_KEY;
  }

  // TODO: Remove when officiants and extras are fully enabled (enableOfficiantsAndExtras)
  if (taxonomyKey === OFFICIANTS_TAXONOMY_KEY || taxonomyKey === EXTRAS_TAXONOMY_KEY) {
    vendorTaxonomyKey = taxonomyKey;
  }

  return vendorTaxonomyKey;
};

const getCategoryLabel = (vendorTaxonomyKey: SearchableVendorTaxonomyKey) => {
  switch (vendorTaxonomyKey) {
    case BAR_SERVICES_TAXONOMY_KEY:
      return 'Bar services and beverages';
    case CATERING_TAXONOMY_KEY:
      return 'Caterers';
    case HAIR_MAKEUP_TAXONOMY_KEY:
      return 'Beauty professionals';
    case PLANNERS_TAXONOMY_KEY:
      return 'Wedding planners';
    case VENUES_TAXONOMY_KEY:
      return 'Wedding and reception venues';
    default:
      return getVendorWordPill(vendorTaxonomyKey);
  }
};

const getLocationLabel = (location: string, vendorTaxonomyKey: SearchableVendorTaxonomyKey) => {
  let category;

  switch (vendorTaxonomyKey) {
    case BANDS_DJS_TAXONOMY_KEY:
      category = 'bands and DJs';
      break;
    case BAR_SERVICES_TAXONOMY_KEY:
      category = 'bar services and beverages';
      break;
    case CAKES_DESSERTS_TAXONOMY_KEY:
      category = 'cakes and desserts';
      break;
    default:
      category = getVendorWordPlural(vendorTaxonomyKey);
  }

  return `${location} ${category}`;
};

export const getCategoryLandingPageBreadcrumb = (taxonomyKey: string) => {
  const vendorTaxonomyKey = getSearchableVendorTaxonomyKey(taxonomyKey);

  return {
    label: getCategoryLabel(vendorTaxonomyKey),
    to: vendorCategoryLandingUrl(vendorTaxonomyKey),
  };
};

export const getStateBreadcrumb = (taxonomyKey: string, stateCode: string, stateSlug: string) => {
  const vendorTaxonomyKey = getSearchableVendorTaxonomyKey(taxonomyKey);
  const stateName = getStateName(stateCode) as string; // The state code comes from a city search location, so it should always be valid

  const label = getLocationLabel(stateName, vendorTaxonomyKey);

  const to = createCanonicalUrl({
    selectedTaxonomy: { key: vendorTaxonomyKey },
    selectedLocation: { slug: stateSlug },
  });

  return {
    label,
    to,
  };
};

export const getLocationBreadcrumb = (taxonomyKey: string, searchLocation: SearchLocationView) => {
  const vendorTaxonomyKey = getSearchableVendorTaxonomyKey(taxonomyKey);
  const label = getLocationLabel(searchLocation.label, vendorTaxonomyKey);
  const to = createCanonicalUrl({
    selectedTaxonomy: { key: vendorTaxonomyKey },
    selectedLocation: searchLocation,
  });
  return {
    label,
    to,
  };
};

export const getCanonicalFacetsBreadcrumb = (
  taxonomyKey: string,
  searchLocation: SearchLocationView,
  canonicalFacets: OptionFacetType[]
) => {
  const vendorTaxonomyKey = getSearchableVendorTaxonomyKey(taxonomyKey);
  const label = (canonicalFacets[0].displayName || canonicalFacets[0].name) as string;
  const to = createCanonicalUrl({
    selectedTaxonomy: { key: vendorTaxonomyKey },
    selectedLocation: searchLocation,
    selectedFacets: canonicalFacets,
  });
  return {
    label,
    to,
  };
};
