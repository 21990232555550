import { useCallback, useEffect, useMemo, useState } from 'react';

import { VENUES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { StorefrontFirstMoveView } from '@zola/svc-marketplace-ts-types';

import { HeaderReviewInfo } from '~/components/common/ui/CoverGalleryV2/HeaderReviewInfo';
import LightboxV2 from '~/components/common/zolaUI/Lightbox/LightboxV2';
import { LightboxEntry, LightboxGroup } from '~/components/common/zolaUI/Lightbox/types';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import {
  DesktopLightboxDek,
  DesktopLightboxHeaderControls,
} from '../components/Lighbox/DesktopLightboxControls';
import { StorefrontDetailsStickyFooter } from '../components/StorefrontDetailsStickyFooter';
import { LightboxContext } from './LightboxContext';
import { useStorefrontDetails } from './StorefrontDetailsContext';

const StorefrontDetailsLightboxHed =
  (storefrontDetails: CouplesStorefrontDetails, firstMove: StorefrontFirstMoveView | null) =>
  (isMobile: boolean) => {
    if (!isMobile && firstMove) {
      return `${storefrontDetails.name} has invited you to inquire`;
    }
    return storefrontDetails.name;
  };

export const StorefrontDetailsLightboxProvider: React.FC<{ noControls?: boolean }> = ({
  children,
  noControls,
}) => {
  const { storefrontDetails, firstMove, realWeddings } =
    useStorefrontDetails<CouplesStorefrontDetails | null>();

  const [selectedPhotoUuid, setSelectedPhotoUuid] = useState<string | undefined>();
  const [initialPhotoGroup, setInitialPhotoGroup] = useState<string | undefined>();

  const coverPhotos: LightboxEntry[] = useMemo(() => {
    if (!storefrontDetails) return [];

    const { coverGallery = [] } = storefrontDetails;
    return coverGallery.map((photo) => ({
      uuid: photo.uuid,
      hed: StorefrontDetailsLightboxHed(storefrontDetails, firstMove),
      group: {
        label: 'Vendor photos',
        owningObjectKey: storefrontDetails.uuid,
      },
    }));
  }, [firstMove, storefrontDetails]);

  const galleryPhotos: LightboxEntry[] = useMemo(() => {
    if (!storefrontDetails) return [];

    const { photoGallery } = storefrontDetails;
    return photoGallery.map((photo) => ({
      uuid: photo.uuid,
      hed: StorefrontDetailsLightboxHed(storefrontDetails, firstMove),
      group: {
        label: 'Vendor photos',
        owningObjectKey: storefrontDetails.uuid,
      },
      photoCredit: photo.photoCreditName,
    }));
  }, [firstMove, storefrontDetails]);

  const photosFromReviews: LightboxEntry[] = useMemo(() => {
    if (!storefrontDetails) return [];

    return storefrontDetails.recommendations.flatMap((review) => {
      return (review.reviewImageIds || []).map((uuid) => ({
        uuid,
        hed: review.title,
        dek: () => <HeaderReviewInfo openPhotoReview={review} />,
        group: {
          label: 'Review photos',
          owningObjectKey: review.reviewUuid,
        },
      }));
    });
  }, [storefrontDetails]);

  const photosFromSpaces: LightboxEntry[] = useMemo(() => {
    if (!storefrontDetails) return [];

    if (storefrontDetails.taxonomyKey === VENUES_TAXONOMY_KEY) {
      const {
        venueDetails: { spaces },
      } = storefrontDetails;

      return spaces.flatMap((space) => {
        const spaceGalleryPhotos = space.venueSpaceGalleryViews.map((photo) => ({
          uuid: photo.imageId,
          hed: StorefrontDetailsLightboxHed(storefrontDetails, firstMove),
          dek: space.name,
          group: {
            label: 'Vendor photos',
            owningObjectKey: space.uuid,
          },
        }));

        if (space.floorplanUuid) {
          spaceGalleryPhotos.push({
            uuid: space.floorplanUuid,
            hed: StorefrontDetailsLightboxHed(storefrontDetails, firstMove),
            dek: `${space.name} floor plan`,
            group: {
              label: 'Vendor photos',
              owningObjectKey: space.uuid,
            },
          });
        }
        return spaceGalleryPhotos;
      });
    }

    return [];
  }, [firstMove, storefrontDetails]);

  const photosFromRealWeddings: LightboxEntry[] = useMemo(() => {
    if (!storefrontDetails) return [];

    return realWeddings.flatMap((wedding) => {
      return wedding.photos
        .filter((photo) => Boolean(photo.imageId))
        .map((photo) => ({
          uuid: photo.imageId as string,
          hed: wedding.title as string,
          group: {
            label: 'Real wedding photos',
            owningObjectKey: wedding.uuid,
          },
          photoCredit: photo.credit,
        }));
    });
  }, [realWeddings, storefrontDetails]);

  const entries: LightboxEntry[] = useMemo(() => {
    return [
      ...coverPhotos,
      ...galleryPhotos,
      ...photosFromSpaces,
      ...photosFromReviews,
      ...photosFromRealWeddings,
    ];
  }, [coverPhotos, galleryPhotos, photosFromSpaces, photosFromReviews, photosFromRealWeddings]);

  const photoGroups: LightboxGroup[] = useMemo(() => {
    const result: LightboxGroup[] = [];

    const counts = entries.reduce((acc, entry) => {
      const { label } = entry.group;

      // Groups get returned in the order they are in the entries
      if (!acc[label]) {
        result.push({ label, count: 1 });
      }

      // Count the number of entries in each group
      const count = acc[label] ? acc[label] + 1 : 1;
      return {
        ...acc,
        [label]: count,
      };
    }, {} as Record<string, number>);

    // Return
    return result.map((group) => ({
      ...group,
      count: counts[group.label],
    }));
  }, [entries]);

  // This state + callback + effect hooks is to handle a bit of an unusual case where clicking
  // a photo loads the storefront details and all the photo to show.  For example, on the listing
  // detail page, we don't have the storefront details and all the photos for all the storefronts
  // so we have to wait until click, then we fetch the storefront.  But... that means, we can't
  // render the lightbox.. So we stash away the request, then when we have the entries loaded
  // we can show the lightbox.
  const [request, setRequest] = useState<{ photoUuid: string | null; photoGroup?: string } | null>(
    null
  );
  const openLightbox = useCallback((photoUuid: string | null, photoGroup?: string) => {
    setRequest({ photoUuid, photoGroup });
  }, []);

  useEffect(() => {
    if (request) {
      const { photoUuid, photoGroup } = request || {};

      if (photoGroup && photoGroups.find(({ label }) => label === photoGroup)) {
        setInitialPhotoGroup(photoGroup);
      } else {
        setInitialPhotoGroup(undefined);
      }

      const toShow = entries.find(({ uuid }) => uuid === photoUuid) || entries[0];
      if (toShow) {
        setSelectedPhotoUuid(toShow.uuid);
      }
    }
  }, [entries, photoGroups, request]);

  const onClose = useCallback(() => {
    setSelectedPhotoUuid(undefined);
  }, []);

  return (
    <LightboxContext.Provider
      value={{
        openLightbox,
        photoGroups,
        initialPhotoGroup,
      }}
    >
      {children}
      {selectedPhotoUuid && entries.length > 0 && (
        <LightboxV2
          entries={entries}
          initialUuid={selectedPhotoUuid}
          onClose={onClose}
          desktopControls={
            noControls ? undefined : <DesktopLightboxHeaderControls onClose={onClose} />
          }
          desktopDek={<DesktopLightboxDek />}
          mobileControls={
            noControls ? undefined : <StorefrontDetailsStickyFooter forceShow onClick={onClose} />
          }
        />
      )}
    </LightboxContext.Provider>
  );
};
